<template>
  <div>
    <v-container fluid class="tneutral-wrapper" >
        <v-row>
            <v-col cols="2">               
                <v-list color="transparent">
                    <v-list-item v-for="(name, index) in stepsName" :key="name">
                        <v-list-item-content>
                            <v-list-item-title :class="{ 'font-weight-bold' : stepper == (index + 1)}">
                                {{`${index + 1}. ${name}` }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>
            <v-col cols="9" >
                <div class="content pl-6" v-if="!loading">            
                    <v-stepper class="elevation-0" alt-labels v-model="stepper">
                        <v-stepper-header ref="stepperScroll">
                            <v-stepper-step color="black" step="1"></v-stepper-step>
                            <v-divider></v-divider> 
                            <v-stepper-step color="black" step="2"></v-stepper-step>
                            <v-divider></v-divider> 
                            <v-stepper-step color="black" step="3"></v-stepper-step>
                            <v-divider></v-divider> 
                            <v-stepper-step color="black" step="4"></v-stepper-step>
                        </v-stepper-header>

                        <v-stepper-items >
                            <v-stepper-content class="pa-0" step="1">   
                                <v-form ref="formrefModel">
                                    <AccountInfo />
                                </v-form>
                            </v-stepper-content>
                            <v-stepper-content class="pa-0" step="2">
                                <v-form ref="formrefModel2">
                                    <OrganizationInfo />
                                </v-form>
                            </v-stepper-content>
                            <v-stepper-content class="pa-0" step="3">
                                <DocumentsUpload v-if="$store.state.reductionFormModel.AccountApplicationForm_Token" @createReductionForm="createReductionForm" />
                            </v-stepper-content>
                            <v-stepper-content class="pa-0" step="4">   
                                <v-form ref="formrefModel4" v-model="termsModel">
                                    <TermsUse v-if="stepper == 4" :touched="touched" />
                                </v-form>
                            </v-stepper-content>                                               
                        </v-stepper-items>
                    </v-stepper>
                    
                    <vue-recaptcha class="mt-6" ref="recaptcha" v-if="stepper == 1" sitekey="6LdJHo8dAAAAAImodGggXFtY1tN6TuuLCZCKJQMD" @verify="verificarcatpach"></vue-recaptcha>

                    <div class="mt-12 d-flex justify-end" >
                        <v-btn width="350" height="45" tile class="tneutral-button-next" color="secondary"  depressed @click="next()" :disabled="(stepper == 1 && !tokenCaptcha)">
                            <span class="text-capitalize">{{titleNext}}</span>
                            <div v-if="stepper != 4">
                                <v-icon>mdi-chevron-right</v-icon>
                                <v-icon style="left:-15px">mdi-chevron-right</v-icon>
                            </div>
                        </v-btn> 
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-snackbar
            v-model="snackbar"
            :timeout="5000"
            >
            {{ errorMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn
                color="red"
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>

        <v-dialog
            v-model="confirmationProject"
            max-width="600"
            persistent
            :retain-focus="false">
            <v-card class="pa-4 align-center">
                <v-card-text class="congratulation">
                    <v-icon color="secondary my-6" size="100">mdi-check-circle-outline</v-icon>
                    <p class="mt-4"><span style="line-height: 32px;" >Your application has been sent</span></p>
                </v-card-text>
                <v-card-actions > 
                    <v-btn outlined class="packsoft-button font-weight-bold mx-auto" @click="goDetail">
                        Go to projects
                    </v-btn>  
                </v-card-actions>      
            </v-card>
        </v-dialog>
    </v-container>
    <!-- Loading  -->
    <v-overlay :value="loading || loadingBG" >
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import {VueRecaptcha} from 'vue-recaptcha'
import axios from 'axios';
import AccountInfo from '../components/reductorapplication/AccountInfo.vue'
import OrganizationInfo from '../components/reductorapplication/OrganizationInfo.vue'
import DocumentsUpload from '../components/reductorapplication/DocumentsUpload.vue'
import TermsUse from '../components/reductorapplication/TermsUse.vue'

export default {
    components:{
        VueRecaptcha,
        AccountInfo,
        OrganizationInfo,
        DocumentsUpload,
        TermsUse
    },
    data: () => {
       return{
        loading: false,
        stepper: 1,
        forma: null,
        loadingBG: false,
        termsModel: null,
        touched: null,
        confirmationProject: null,
        tokenCaptcha: null,
        snackbar: null,
        errorMessage: null,
        stepsName: ['Account Manager Info','Organization Info','Document Upload','Terms of Use']
       }
    },
    computed: {
        ...mapState(['vars','api','reductionFormModel']),
        getPathName(){
            return ''
        },
        getUrlHash(){
            return ''
        },
        titleNext(){
            return this.stepper < 4 ? 'Continue' : 'Submit for approval'
        },
    },
    async created(){
        if (this.id && this.token){
            this.loading = true;
            await this.getReductionForm()
            this.loading = false;
        } 
        this.getVars().then(()=>{})
    },
    methods: {
        ...mapMutations(['SET_REDUCTION_MODEL']),
        verificarcatpach(token){
            this.tokenCaptcha = token;
        },
        async next(){
            
            switch (this.stepper) {
                case 1:
                    if (this.$refs.formrefModel.validate() && this.tokenCaptcha){
                        await this.createReductionForm();
                        this.stepper++;
                        this.$refs.stepperScroll.scrollIntoView({ behavior: "smooth" });
                    }else{
                        this.scrollToError()
                    }
                break;
                case 2:
                    if (this.$refs.formrefModel2.validate()){
                        await this.createReductionForm();
                        this.stepper++;
                        this.$refs.stepperScroll.scrollIntoView({ behavior: "smooth" });
                    }else{
                        this.scrollToError()
                    }
                break;
                case 3:
                    if (this.reductionFormModel.AccountApplicationForm_AccountManagerDocumentId
                        && this.reductionFormModel.AccountApplicationForm_OrganizationTaxIdentificationDocument
                        && this.reductionFormModel.AccountApplicationForm_OrganizationRepresentativeDocument){
                            this.stepper++;
                            this.$refs.stepperScroll.scrollIntoView({ behavior: "smooth" });
                    } else{
                        this.errorMessage = 'All documents are required';
                        this.snackbar = true;
                    }
                    
                break;
                case 4:
                    if(this.$refs.formrefModel4.validate() && this.$store.state.reductionFormModel.AccountApplicationForm_LegalTermsSigned == 1){
                        try {
                            this.reductionFormModel.AccountApplicationForm_Status = 'requested';
                            await this.createReductionForm();
                            this.confirmationProject = true;
                        } catch (responseError) {
                            this.errorMessage =  'Service not available. Try again';
                            if (responseError.errors && responseError.errors.length > 2) {
                                let parsedError = JSON.parse(responseError.errors[2]);
                                if (parsedError && parsedError.error && parsedError.error.message) {
                                    this.errorMessage = parsedError.error.message;
                                }
                            }
                            
                        }
                    }else{
                        this.touched = true;
                    }                            
                break;
            }
            
        },
        goBackStep(){
            this.stepper--;
        },
        getVars(){
            return new Promise((resolve, reject) => {   
                try {
                    this.$store.dispatch('getVars').then((d)=>{
                        resolve(d);
                    });
                } catch (error) {
                    reject(error)
                    console.log("ERROR",error);
                }
            })                                    
        },
        scrollToError(){
            setTimeout(() => {
                const el = document.querySelectorAll(".v-messages__message")[0];
                try {
                    el.scrollIntoView({block: "center", behavior: "smooth" })
                } catch (error) {
                    //
                }
            }, 200);
        },       
        getReductionForm(){
            return  new Promise((resolve, reject) => {
                axios.get(`${this.api}/account_application_forms/${this.id}?AccountApplicationForm_Token=${this.token}`).then( response => {
                    this.SET_REDUCTION_MODEL(response.data.data.account_application_form);                        
                    resolve(response.data.data.account_application_form);
                }).catch( error =>{
                    reject(error)
                });
            })
        },
        createReductionForm(){
            this.loadingBG = true;
            //Format data 
            let reduction = {...this.reductionFormModel};
            let method = 'POST';
            let url = 'https://api7.cloudframework.io/tneutral/public/account_application_forms';

            //Update
            if (reduction.AccountApplicationForm_Id && reduction.AccountApplicationForm_Token){
                url = `https://api7.cloudframework.io/tneutral/public/account_application_forms/${reduction.AccountApplicationForm_Id}?AccountApplicationForm_Token=${reduction.AccountApplicationForm_Token}`;
                method = 'PUT';
                reduction.AccountApplicationForm_IsSameAddress = reduction.AccountApplicationForm_IsSameAddress ? 1 : 0;
                reduction.AccountApplicationForm_LegalTermsSigned = reduction.AccountApplicationForm_LegalTermsSigned ? 1 : 0;
            }
            reduction['g-recaptcha-response'] = this.tokenCaptcha;
                        
            return  new Promise((resolve, reject) => {
                try {
                    axios({method,url,data: reduction}).then( response => {
                        this.loadingBG = false;
                        this.SET_REDUCTION_MODEL(response.data.data.account_application_form);                        
                        resolve(response.data.data.account_application_form);
                    }).catch( error =>{
                        if(this.stepper == 1){
                            this.tokenCaptcha = null;
                            this.$refs.recaptcha.reset();
                        }
                        if (error.response){
                            this.errorMessage =  error.response.data.message ? error.response.data.message : 'Service not available. Try again';
                            this.snackbar = true;
                            this.loadingBG = false;
                        }
                        reject(error);
                    });
                } catch (error) {
                    this.loadingBG = false;
                    this.$refs.recaptcha.reset();
                    reject(error)
                }               
            })
             
        },
        goDetail(){
            this.$router.push({path: '/'})
        },
    }
}
</script>
<style scoped >
.container{
    max-width: 100% !important
}

</style>

<style>
.v-stepper {
    background-color: transparent !important;
    box-shadow: none !important;
}

.v-stepper__header {
    box-shadow: none !important;
    max-width: 400px;
    margin: 0 auto;
}

.v-stepper__header hr {
    margin-top: 42px !important;
}

.v-stepper__step{
    padding: 27px!important;
}
.v-stepper__step__step {
    width: 35px !important;;
    height: 35px !important;;
    font-size: 28px !important;
    font-weight: 500!important;
    background-color: white !important;
    color: black !important;
    border: 1px solid black ;
}

span.v-stepper__step__step.black{
    color: white !important;
}

span.v-stepper__step__step.primary{
    font-weight: 500;
}

.v-stepper__step.v-stepper__step--active span.v-stepper__step__step.primary{
    color: white !important;
}

.v-stepper__label{
    text-align: center !important;
    text-transform: none;
}

.v-stepper__step--active .v-stepper__label {
    color: white !important;
}

.v-stepper__wrapper {
    box-shadow: none !important;
} 

/*FORM STYLES*/
/*Separation between inputs columns*/
.col-md-6.col-12:nth-child(odd){
    padding-right: 5%;
}
.col-md-6.col-12:nth-child(even){
    padding-left: 5%;
}

.info-icon-input{
    position: absolute;
    top: 30px;
    left: 0;
}
.terms-condition{
    text-decoration: underline !important;
    color: black!important;
}

.congratulation{
    font-size: 32px;
    text-align: center;
}

.v-stepper .v-divider, .v-item-group .v-divider{
    border-top: 2px solid black !important;
}
.cursor-pointer{
    cursor: pointer;
}
@media (max-width:1379px)  {
   .col-md-6.col-12:nth-child(even){
        padding-left: 6%;
    }
}
@media (max-width:959px)  {
    .col-md-6.col-12{
        padding: 2% !important
    }
}
@media (min-width:1904px)  {
    .info-icon-input{
        left: 25px;
    }
}
</style>