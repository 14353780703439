
<template>
  <div class="organization-info">
    <h2 class="text-center mx-auto d-block mb-4">ORGANIZATION INFO</h2>
    <v-alert class="tneutral-alert-info" color="#F2F3ED">
        <p class="mb-0">
          Please, fullfil and attach all information required to identify and contact the Organization owner of the Reduction Projects.
        </p>
    </v-alert>
    <h2 class="mb-0">General Information</h2>
    <v-divider class="mt-2" color="black"></v-divider>
    <div class="pt-4 pb-5" autocomplete="off" >
        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field validate-on-blur v-model="reductionFormModel.AccountApplicationForm_OrganizationName"
                            label="Organization Name *"
                            :rules="ruleRequired"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-autocomplete 
                            validate-on-blur
                            v-model="reductionFormModel.AccountApplicationForm_OrganizationType"
                            :items="vars.organization_types"
                            item-text="OrganizationType_Name"
                            item-value="OrganizationType_Code"
                            :menu-props="{ maxHeight: '400' }"
                            :rules="ruleRequired"
                            label="Type *"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field validate-on-blur v-model="reductionFormModel.AccountApplicationForm_OrganizationWeb"
                          label="Website"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field validate-on-blur v-model="reductionFormModel.AccountApplicationForm_OrganizationAddress"
                            label="Contact Address *"
                            :rules="ruleRequired"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field validate-on-blur v-model="reductionFormModel.AccountApplicationForm_OrganizationCity" 
                            label="City *"
                            :rules="ruleRequired"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field validate-on-blur v-model="reductionFormModel.AccountApplicationForm_OrganizationZipCode" 
                            label="Zip Code *"
                            :rules="ruleRequired"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-autocomplete 
                            validate-on-blur
                            v-model="reductionFormModel.AccountApplicationForm_OrganizationCountry"
                            :items="vars.countries"
                            item-text="CRMCountry_Name"
                            item-value="CRMCountry_Id"
                            :menu-props="{ maxHeight: '400' }"
                            :rules="ruleRequired"
                            label="Country *"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field validate-on-blur v-model="reductionFormModel.AccountApplicationForm_OrganizationTelephone" 
                            label="Telephone *"
                            :rules="ruleRequired"
                        ></v-text-field>
                    </v-col>                  
                </v-row>
            </v-col>           
        </v-row>
    </div>
    <h2 class="mt-12 mb-0">Billing Information</h2>
    <v-divider class="mt-2" color="black"></v-divider>
    <div class="pt-4" autocomplete="off" >
        <v-row>
            <v-col cols="12" md="6">
                <v-text-field validate-on-blur v-model="reductionFormModel.AccountApplicationForm_OrganizationBillingName"
                    label="Business Name *"
                    :rules="ruleRequired"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" style="position: relative;">                
                 <v-text-field validate-on-blur 
                    v-model="reductionFormModel.AccountApplicationForm_OrganizationTaxIdentificationNumber"
                    label="VAT / Business Registration Number *"
                    :rules="ruleRequired"
                ></v-text-field>
                <v-btn color="secondary" class="info-icon-input" icon @click="openInformation">
                    <v-icon size="30">mdi-information</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
             <v-col cols="12">
                <div class="tn-checkbox-wrapper">
                    <div class="tn-checkbox-round">
                        <input id="checkbox"  v-model="reductionFormModel.AccountApplicationForm_IsSameAddress" type="checkbox" :checked="reductionFormModel.AccountApplicationForm_IsSameAddress"  />
                        <label for="checkbox"></label>
                    </div>
                    <label for="checkbox" class="mt-1 cursor-pointer">Billing Address same as Contact Address</label>
                </div>
            </v-col>
        </v-row>
        <v-scroll-y-transition>
            <v-row v-if="!reductionFormModel.AccountApplicationForm_IsSameAddress">
                <v-col cols="12" md="6">
                    <v-text-field validate-on-blur v-model="reductionFormModel.AccountApplicationForm_OrganizationBillingAddress"
                        label="Billing Address *"
                        :rules="ruleRequired"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field validate-on-blur v-model="reductionFormModel.AccountApplicationForm_OrganizationBillingCity" 
                        label="Billing City *"
                        :rules="ruleRequired"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field validate-on-blur v-model="reductionFormModel.AccountApplicationForm_OrganizationBillingZipCode" 
                        label="Billing Zip Code *"
                        :rules="ruleRequired"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">                   
                    <v-autocomplete 
                        validate-on-blur
                        v-model="reductionFormModel.AccountApplicationForm_OrganizationBillingCountry"
                        :items="vars.countries"
                        item-text="CRMCountry_Name"
                        item-value="CRMCountry_Id"
                        :menu-props="{ maxHeight: '400' }"
                        :rules="ruleRequired"
                        label="Billing Country *"
                        clearable
                    ></v-autocomplete>
                </v-col>                                 
            </v-row>
        </v-scroll-y-transition>
    </div>
    <ConfirmDialog :dialogProp="dialogProp"/>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import ConfirmDialog from '../common/ConfirmDialog.vue'
export default {
    components:{
        ConfirmDialog
    },
   data: function() {
    return {
        dialogProp: {}
    };
  },
  mounted(){
  },
  computed: {
    ...mapState([
      'api',
      'reductionFormModel',
      'vars'
    ]),
    ruleRequired() {
        // eslint-disable-next-line no-extra-boolean-cast
        return [v => (Array.isArray(v) ? v.length > 0 : !!v) || '* this field is required']
    },
    ruleURL(){
        return [v =>  /(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(v) || 'this web URL is incorrect' ]
    }
  },
  methods:{
    openInformation() {
      this.dialogProp = {
        title: "Attention!",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac condimentum neque. Integer imperdiet porttitor porta. Praesent finibus nec est vel mattis. Cras fermentum nisi a odio molestie, sit amet interdum",
        dialog: true,
      };
    },  
  }
};
</script>

<style scoped>
/*FORM STYLES*/
/*Separation between inputs columns*/
.col-sm-6.col-md-4.col-12:nth-child(3n-2){
    padding-right: 8%;
}

.col-sm-6.col-md-4.col-12:nth-child(3n-3){
    padding-left: 8%;
}


@media (max-width: 990px){
    .col-sm-6.col-md-4.col-12:nth-child(3n-2){
        padding-right: 12px!important;
    }

    .col-sm-6.col-md-4.col-12:nth-child(3n-3){
        padding-left: 12px !important;
    }
}

</style>