
<template>
  <div class="terms-use">
    <h2 class="text-center mx-auto d-block mb-4">TERMS OF USE</h2>
    <v-alert class="tneutral-alert-info" color="#F2F3ED">
        <p class="mb-0">
          Please, carefully review and accept the T-Neutral Platform Terms of Use to finalize the application. If needed, you can download them <a class="black--text text-decoration-underline">here</a> .
        </p>
    </v-alert>
    <h2>Review and accept the following Terms of Use to continue to the Application Form</h2>
    <div class="overflow-auto terms-of">
      <p class="px-6 py-8 text-justify">
        <label class="h3 font-weight-bold d-block">TERMS OF USE</label>
        T-Neutral Platform for Reduction Projects
        <br>
        November 2021
        <br><br><br>

        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer interdum dui vitae nisi elementum elementum. Suspendisse potenti. Cras fringilla sem congue diam condimentum posuere. Aenean non accumsan felis. Ut a nunc id est faucibus pellentesque. Aliquam blandit pharetra fermentum. Aliquam a porttitor ipsum. Praesent dictum nibh mollis tortor ullamcorper convallis. Sed lobortis vitae ex sit amet pharetra. Fusce placerat vel velit quis feugiat. Duis semper gravida tortor quis pulvinar. <br><br> Quisque accumsan vehicula dictum. Donec malesuada auctor urna, vitae pretium neque. Duis blandit elit sit amet mauris vehicula commodo. Donec eros nulla, aliquam vitae tempus vitae, vestibulum quis nulla.

        Aliquam erat volutpat. Sed semper sodales risus, nec bibendum diam malesuada id. Ut hendrerit libero justo, nec blandit ipsum auctor vitae. <br><br>Vestibulum accumsan magna a nisl mattis, ac malesuada purus mattis. Aenean sem ante, convallis eget ante ac, cursus maximus velit. Aenean ut tortor posuere, dapibus nunc at, varius massa. Donec in magna eros. Aenean erat erat, efficitur eget mollis eget, mollis non sapien.

        Nam id feugiat quam. Maecenas quis ante in metus viverra ultrices vel vel lectus. Suspendisse sed auctor sem, id scelerisque arcu. Aliquam cursus quam odio, sit amet aliquet neque consectetur ut. Mauris eu leo blandit, porta urna in, euismod neque. Maecenas non pharetra quam. Sed ut erat facilisis, aliquam magna vitae, luctus lacus. Morbi pharetra neque eu accumsan elementum. Aenean vehicula efficitur dignissim. Nulla ut nunc mauris. Maecenas egestas congue lacus ut varius.

        Cras quis sapien nisi. Mauris feugiat ornare sapien id hendrerit. Curabitur vel pharetra metus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean vulputate dignissim vehicula. Cras odio dui, condimentum quis dignissim sed, mattis id nunc. Nullam id posuere metus. Ut ut aliquam sem, ut condimentum metus. Pellentesque imperdiet sollicitudin lacus, eu rhoncus nulla varius ut. Interdum et malesuada fames ac ante ipsum primis in faucibus. In quis lorem quis eros tincidunt scelerisque vel vitae nulla. Mauris volutpat mauris nunc, quis tristique sapien bibendum eget.

        Nulla sem arcu, dictum sit amet massa vel, aliquet blandit ex. Suspendisse at consequat metus. Praesent imperdiet mattis nibh, eget faucibus velit eleifend et. Donec et nunc sit amet magna semper hendrerit sed vel leo. Donec aliquam hendrerit nisl, in pretium urna blandit in. Vestibulum eu pulvinar enim. Cras pellentesque mi id nibh accumsan ullamcorper. Sed vestibulum diam vel pellentesque efficitur. Curabitur nibh sapien, luctus quis dui in, dignissim gravida velit.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer interdum dui vitae nisi elementum elementum. Suspendisse potenti. Cras fringilla sem congue diam condimentum posuere. Aenean non accumsan felis. Ut a nunc id est faucibus pellentesque. Aliquam blandit pharetra fermentum. Aliquam a porttitor ipsum. Praesent dictum nibh mollis tortor ullamcorper convallis. Sed lobortis vitae ex sit amet pharetra. Fusce placerat vel velit quis feugiat. Duis semper gravida tortor quis pulvinar. Quisque accumsan vehicula dictum. Donec malesuada auctor urna, vitae pretium neque. Duis blandit elit sit amet mauris vehicula commodo. Donec eros nulla, aliquam vitae tempus vitae, vestibulum quis nulla.

        Aliquam erat volutpat. Sed semper sodales risus, nec bibendum diam malesuada id. Ut hendrerit libero justo, nec blandit ipsum auctor vitae. Vestibulum accumsan magna a nisl mattis, ac malesuada purus mattis. Aenean sem ante, convallis eget ante ac, cursus maximus velit. Aenean ut tortor posuere, dapibus nunc at, varius massa. Donec in magna eros. Aenean erat erat, efficitur eget mollis eget, mollis non sapien.

        Nam id feugiat quam. Maecenas quis ante in metus viverra ultrices vel vel lectus. Suspendisse sed auctor sem, id scelerisque arcu. Aliquam cursus quam odio, sit amet aliquet neque consectetur ut. Mauris eu leo blandit, porta urna in, euismod neque. Maecenas non pharetra quam. Sed ut erat facilisis, aliquam magna vitae, luctus lacus. Morbi pharetra neque eu accumsan elementum. Aenean vehicula efficitur dignissim. Nulla ut nunc mauris. Maecenas egestas congue lacus ut varius.

        Cras quis sapien nisi. Mauris feugiat ornare sapien id hendrerit. Curabitur vel pharetra metus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean vulputate dignissim vehicula. Cras odio dui, condimentum quis dignissim sed, mattis id nunc. Nullam id posuere metus. Ut ut aliquam sem, ut condimentum metus. Pellentesque imperdiet sollicitudin lacus, eu rhoncus nulla varius ut. Interdum et malesuada fames ac ante ipsum primis in faucibus. In quis lorem quis eros tincidunt scelerisque vel vitae nulla. Mauris volutpat mauris nunc, quis tristique sapien bibendum eget.

        Nulla sem arcu, dictum sit amet massa vel, aliquet blandit ex. Suspendisse at consequat metus. Praesent imperdiet mattis nibh, eget faucibus velit eleifend et. Donec et nunc sit amet magna semper hendrerit sed vel leo. Donec aliquam hendrerit nisl, in pretium urna blandit in. Vestibulum eu pulvinar enim. Cras pellentesque mi id nibh accumsan ullamcorper. Sed vestibulum diam vel pellentesque efficitur. Curabitur nibh sapien, luctus quis dui in, dignissim gravida velit.
      </p>      
    </div>
    <div class="tn-checkbox-wrapper mt-12">
      <div class="tn-checkbox-round">
          <input id="checkbox2" type="checkbox"  
          v-model="reductionFormModel.AccountApplicationForm_LegalTermsSigned" 
          :checked="reductionFormModel.AccountApplicationForm_LegalTermsSigned"  />
          <label for="checkbox2"></label>
      </div>
      <div>
        <label for="checkbox2" class="mt-0 ml-2 cursor-pointer" @click="reductionFormModel.AccountApplicationForm_LegalTermsSigned = !reductionFormModel.AccountApplicationForm_LegalTermsSigned">I have reviewed the above Terms & Conditions and have the authority to agree and bind the Organization represented in this application.</label>
        <v-scroll-y-transition> 
          <div v-if="touched && reductionFormModel.AccountApplicationForm_LegalTermsSigned == false" class="terms-invalid v-text-field__details mt-2">
              <div class="v-messages theme--light error--text">
                  <div class="v-messages__wrapper">
                      <div class="v-messages__message">
                          * this field is required
                      </div>
                  </div>
              </div>
          </div>
        </v-scroll-y-transition>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
   data: function() {
    return {
      termsModel: null
    };
  },
  mounted(){
  },
  computed: {
    ...mapState([
      'reductionFormModel'
    ])
  },
  methods:{
  },
  watch:{
  },
  props:['touched']
};
</script>

<style scoped>
.terms-of{
  max-height: 500px;
  margin-top: 30px;
  box-shadow: 0px 0px 2px 1px inset var(--tneutral-secondary-color) !important;
  border-radius: 0 10px 10px 0;
}
.terms-of p{
  font-size: 14px;
  font-weight: 400;
}

/* SCROLL */
/* width */
 ::-webkit-scrollbar {
  width: 20px;
}

/* Track */
 ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
 ::-webkit-scrollbar-thumb {
  background: var(--tneutral-secondary-color); 
  border-radius: 10px;
}

/* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
  background: var(--tneutral-secondary-color); 
}
</style>