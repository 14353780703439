
<template>
  <div class="documents-upload">
    <h2 class="text-center mx-auto d-block mb-4">DOCUMENT UPLOAD</h2>
    <v-alert class="tneutral-alert-info" color="#F2F3ED">
        <p class="mb-0">
          Upload all "Know Your Customer" and other legally required documents for correct identification and validation of Account Manager and Organization. Please, have them ready before hand to be able to finalize this aplication. Find <span class="text-decoration-underline">here</span> a list of all documents needed to prepare them in advance.
        </p>
    </v-alert>
    <h2 class="mb-0">"Know Your Customer" Documentation <span>(*all files required)</span></h2>
    <v-divider class="mt-2" color="black"></v-divider>
    <v-row no-gutters class="documentation-box">
      <v-col cols="12" sm="6">
        <label class="sub-title"
          >Account Manager Personal ID *
          <v-icon @click="openInformationDlg()"
            size="32"
            color="secondary"
            class="ml-2"
          >
            mdi-information
          </v-icon>
        </label>
        <p>
          Please upload any document we can use to verify you personal identity.
        </p>
      </v-col>
      <v-col cols="12" offset-sm="1" sm="5" class="text-end">         
        <ul class="pa-0" v-if="uploadedDocuments.AccountApplicationForm_AccountManagerDocumentId">
          <li class="d-flex align-center doc-link">            
            <div class="d-flex justify-space-between" style="width:100%">
              <a class="px-1"
                :href="uploadedDocuments.AccountApplicationForm_AccountManagerDocumentId.AccountApplicationFormFile_URL"
                text target="_blank">
                <span class="doc-title">{{uploadedDocuments.AccountApplicationForm_AccountManagerDocumentId.AccountApplicationFormFile_Name}}</span>                
              </a>
              <span v-if="uploadedDocuments.FootprintPracticeFile_Status" class="caption red--text">{{uploadedDocuments.FootprintPracticeFile_Status}}</span>
            </div>              
            <v-btn text @click="openConfirmationDlg(uploadedDocuments.AccountApplicationForm_AccountManagerDocumentId.AccountApplicationFormFile_Id, 'AccountApplicationForm_AccountManagerDocumentId')" >
              <v-icon color="var(--tneutral-strong-gray)">mdi-close-circle</v-icon><span class="delete-text">delete</span>
            </v-btn>
          </li>
        </ul>
        <file-pond v-else class="dropzone-styling"
          id="AccountApplicationForm_AccountManagerDocumentId"
          name="files"
          ref="pond"
          :label-idle="labelDropzone"
          credits=""
          v-bind:allow-multiple="true"
          accepted-file-types="image/jpeg, image/png"
          @processfile="(ev,ev2)=> {documentUploaded(ev,ev2,'AccountApplicationForm_AccountManagerDocumentId')}"
          :server="`${api}/account_application_form_files?${formData}`"
        />      
      </v-col>
    </v-row>
     
    <v-row no-gutters class="documentation-box">
      <v-col cols="12" sm="6">
        <label class="sub-title"
          >Organization Registration Number *
          <v-icon @click="openInformationDlg()"
            size="32"
            color="secondary"
            class="ml-2"
          >
            mdi-information
          </v-icon>
        </label>
        <p>
          Please upload any document we can use to verify you are a legally constituted company or independent professional.
        </p>
      </v-col>
      <v-col cols="12" offset-sm="1" sm="5" class="text-end">         
        <ul class="pa-0" v-if="uploadedDocuments.AccountApplicationForm_OrganizationTaxIdentificationDocument">
          <li class="d-flex align-center doc-link" >           
            <div class="d-flex justify-space-between" style="width:100%">
              <a class="px-1"
                :href="uploadedDocuments.AccountApplicationForm_OrganizationTaxIdentificationDocument.AccountApplicationFormFile_URL"
                text target="_blank">
                <span class="doc-title">{{uploadedDocuments.AccountApplicationForm_OrganizationTaxIdentificationDocument.AccountApplicationFormFile_Name}}</span>                
              </a>
              <span v-if="uploadedDocuments.FootprintPracticeFile_Status" class="caption red--text">{{uploadedDocuments.FootprintPracticeFile_Status}}</span>
            </div> 
             <v-btn text @click="openConfirmationDlg(uploadedDocuments.AccountApplicationForm_OrganizationTaxIdentificationDocument.AccountApplicationFormFile_Id, 'AccountApplicationForm_OrganizationTaxIdentificationDocument')" >
              <v-icon color="var(--tneutral-strong-gray)">mdi-close-circle</v-icon><span class="delete-text">delete</span>
            </v-btn>             
          </li>
        </ul>
        <file-pond v-else class="dropzone-styling"
          id="AccountApplicationForm_OrganizationTaxIdentificationDocument"
          name="files"
          ref="pond"
          credits=""
          :label-idle="labelDropzone"
          v-bind:allow-multiple="true"
          accepted-file-types="image/jpeg, image/png"
          @processfile="(ev,ev2)=> {documentUploaded(ev,ev2,'AccountApplicationForm_OrganizationTaxIdentificationDocument')}"
          :server="`${api}/account_application_form_files?${formData}`"
        />      
      </v-col>
    </v-row>
    
    <h2 class="mb-0">Other legally Required Documentation</h2>
    <v-divider class="mt-2" color="black"></v-divider>
    <v-row no-gutters class="documentation-box">
      <v-col cols="12" sm="6">
        <label class="sub-title"
          >Operating Representative Document *
          <v-icon @click="openInformationDlg()"
            size="32"
            color="secondary"
            class="ml-2"
          >
            mdi-information
          </v-icon>
        </label>
        <p>
          Please upload a copy of the Operating Representative Document signed by Account Manager and Organization.
        </p>
      </v-col>
      <v-col cols="12" offset-sm="1" sm="5" class="text-end">         
        <ul class="pa-0" v-if="uploadedDocuments.AccountApplicationForm_OrganizationRepresentativeDocument">
          <li class="d-flex align-center doc-link">           
            <div class="d-flex justify-space-between" style="width:100%">
              <a class="px-1"
                :href="uploadedDocuments.AccountApplicationForm_OrganizationRepresentativeDocument.AccountApplicationFormFile_URL"
                text target="_blank">
                <span class="doc-title">{{uploadedDocuments.AccountApplicationForm_OrganizationRepresentativeDocument.AccountApplicationFormFile_Name}}</span>                
              </a>
              <span v-if="uploadedDocuments.FootprintPracticeFile_Status" class="caption red--text">{{uploadedDocuments.FootprintPracticeFile_Status}}</span>
            </div>   
             <v-btn text @click="openConfirmationDlg(uploadedDocuments.AccountApplicationForm_OrganizationRepresentativeDocument.AccountApplicationFormFile_Id, 'AccountApplicationForm_OrganizationRepresentativeDocument')" >
              <v-icon color="var(--tneutral-strong-gray)">mdi-close-circle</v-icon><span class="delete-text">delete</span>
            </v-btn>           
          </li>
        </ul>
        <file-pond v-else class="dropzone-styling"
          id="AccountApplicationForm_OrganizationRepresentativeDocument"
          name="files"
          ref="pond"
          credits=""
          :label-idle="labelDropzone"
          v-bind:allow-multiple="true"
          accepted-file-types="image/jpeg, image/png"
          @processfile="(ev,ev2)=> {documentUploaded(ev,ev2,'AccountApplicationForm_OrganizationRepresentativeDocument')}"
          :server="`${api}/account_application_form_files?${formData}`"
        />      
      </v-col>
    </v-row>
    <ConfirmDialog :dialogProp="dialogProp" @clicked="onConfirmationDlg" /> 
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import axios from 'axios'
import ConfirmDialog from '../common/ConfirmDialog.vue'
import vueFilePond from 'vue-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import FilePond plugins
const FilePond = vueFilePond()
export default {
  components:{
    FilePond,
    ConfirmDialog
  },
   data: function() {
    return {
      loading: null,
      cssDropzone: `
        cursor:pointer;
        padding-bottom:0px;
        padding-top:0px;
        font-size:14px;margin:0;font-weight: 400;
        color:var(--tneutral-main-color);
        line-height: 18px;
        margin-top: 5px;
        text-align: end
      `,
      uploadedDocuments:{
        'AccountApplicationForm_AccountManagerDocumentId': null,
        'AccountApplicationForm_OrganizationTaxIdentificationDocument': null,
        'AccountApplicationForm_OrganizationRepresentativeDocument': null
      },
      dialogProp: {
        title: "Attention!",
        description:
          "${info}ServicLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac condimentum neque. Integer imperdiet porttitor porta. Praesent finibus nec est vel mattis. Cras fermentum nisi a odio molestie, sit amet interdum ex dictum. Vivamus vitae velit vitae ex convallis elementum.",
        cancel: "Cancel",
        confirm: "Confirm",
        dialog: false,
      },
    };
  },
  created(){
    Promise.all([this.getFiles(this.reductionFormModel.AccountApplicationForm_AccountManagerDocumentId),
      this.getFiles(this.reductionFormModel.AccountApplicationForm_OrganizationTaxIdentificationDocument), 
      this.getFiles(this.reductionFormModel.AccountApplicationForm_OrganizationRepresentativeDocument)
    ])
    .then((response)=>{
      if (response[0]){        
        response[0].AccountApplicationFormFile_Name = this.getFileName(response[0].AccountApplicationFormFile_Name);
        this.uploadedDocuments.AccountApplicationForm_AccountManagerDocumentId = response[0];
      }
      if(response[1]){
        response[1].AccountApplicationFormFile_Name = this.getFileName(response[1].AccountApplicationFormFile_Name);
        this.uploadedDocuments.AccountApplicationForm_OrganizationTaxIdentificationDocument = response[1];
      }
      if(response[2]){
        response[2].AccountApplicationFormFile_Name = this.getFileName(response[2].AccountApplicationFormFile_Name);
        this.uploadedDocuments.AccountApplicationForm_OrganizationRepresentativeDocument = response[2];
      }
    }).catch(()=>{
      this.uploadedDocuments.AccountApplicationForm_AccountManagerDocumentId = null;
      this.uploadedDocuments.AccountApplicationForm_OrganizationTaxIdentificationDocument = null;
      this.uploadedDocuments.AccountApplicationForm_OrganizationRepresentativeDocument = null;
    })
  },
  computed: {
    ...mapState([
      'reductionFormModel',
      'api'
    ]),
    iconText() {
      return `assets/add-button-svgrepo-com.svg`;
    },
    formData(){
      return `AccountApplicationFormFile_AccountApplicationFormToken=${this.reductionFormModel.AccountApplicationForm_Token}&AccountApplicationFormFile_AccountApplicationForm=${this.reductionFormModel.AccountApplicationForm_Id}`;
    },
    labelDropzone(){
      return `<div style="color:#abb6c1;text-align:center;font-weight: 500; ${this.iconText ? 'display: flex;gap: 10px;align-items: end;justify-content: end;cursor:pointer;' : ''}"> 
            <p>Click or Drag <br> documents to upload</p>
          </div>`
    }
  },
  methods:{    
    ...mapMutations([
      'SET_LOADING',
    ]),
    documentUploaded(...ev){
        if (ev[0] == null){
          const fileInfo = JSON.parse(ev[1].serverId);
          fileInfo.data.files[0].AccountApplicationFormFile_Name = this.getFileName(fileInfo.data.files[0].AccountApplicationFormFile_Name);
          
          this.reductionFormModel[ev[2]] = fileInfo.data.files[0].AccountApplicationFormFile_Id;
          this.uploadedDocuments[ev[2]] = fileInfo.data.files[0];
          this.$listeners.createReductionForm();    
        }else{
          //error
          console.log("Event error", ev.detail);        
        }
    },
    getFileName(file){
        try {
            let url  = file.split(".");
            return ` ${url[0].substring(0,5)}...${url[0].slice(-3)}.${url[1]} `;
        } catch (error) {
        console.log("e",error);
            return  "";
        }
    },
    getFiles(fileId){  
      return  new Promise((resolve, reject) => {
        if (fileId){
          axios.get(`${this.api}/account_application_form_files/${fileId}?AccountApplicationFormFile_AccountApplicationFormToken=${this.reductionFormModel.AccountApplicationForm_Token}&AccountApplicationFormFile_AccountApplicationForm=${this.reductionFormModel.AccountApplicationForm_Id}`)
          .then(response=>{
            resolve(response.data.data.account_application_form_file)
          })
          .catch(()=>{
            reject(null)
          })
        }else{
          resolve(null);
        }          
      })
    },    
    openConfirmationDlg(fileId, code) {
      this.dialogProp = {
        title: "Attention!",
        description: "Do you want to delete this document? This can't be reverted.",
        dialog: true,
        cancel: 'Cancel',
        confirm: 'Confirm',
        data: {fileId, code}
      };
    },
    onConfirmationDlg(args) {
      if(args.event == 'confirm'){
        axios.delete(`${this.api}/account_application_form_files/${args.data.fileId}?AccountApplicationFormFile_AccountApplicationFormToken=${this.reductionFormModel.AccountApplicationForm_Token}&AccountApplicationFormFile_AccountApplicationForm=${this.reductionFormModel.AccountApplicationForm_Id}`)
        .then(()=>{
          this.uploadedDocuments[args.data.code] = null;
          this.reductionFormModel[args.data.code] = null;
          this.$emit('createReductionForm');  
        }).catch(error =>{
          console.log("error",error);
        })
      }
    },
    openInformationDlg() {
      this.dialogProp = {
        title: "Info title!",
        description: "More info",
        dialog: true
      };
    },
  }
};
</script>

<style scoped>
h2 span{
  color: var(--tneutral-secondary-color);
  font-weight: 500;
  font-size: 20px;
}
.documentation-box {
  padding: 20px 5px;
  align-items: start;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 40px !important;
}

.documentation-box h2,
.documentation-box p {
  color: black;
  font-weight: 500 !important;
}
.sub-title {
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 400 !important;
  color: var(--tneutral-secondary-color);
}
.doc-link{
  border-top: 2px solid rgb(232,232,232);
}
.doc-link:last-child{
  border-bottom: 2px solid rgb(232,232,232);
}
.doc-link a, .doc-link2 a {
  text-decoration: unset !important;
}
.doc-title{
  font-size: 14px;
  color: var(--tneutral-soft-gray);
  text-decoration-color:  white !important;
}
.delete-text{
  color: var(--tneutral-soft-gray);
  text-transform: none !important;
  margin-left: 3px;
  letter-spacing: normal;
  font-weight: 600;
}

</style>

<style>
  .filepond--panel-root {
    background-color: white;
  }
  .dropzone-styling{
    cursor:pointer;
    padding:2px;    
    background-image: linear-gradient(to right, var(--tneutral-main-color) 50%, transparent 50%), linear-gradient(to right, var(--tneutral-main-color) 50%, transparent 50%), linear-gradient(to bottom, var(--tneutral-main-color) 50%, transparent 50%), linear-gradient(to bottom, var(--tneutral-main-color) 50%, transparent 50%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 20px 1px, 20px 1px, 1px 20px, 1px 20px;
  }
  .dropzone-styling p{
    color:var(--tneutral-main-color);
    font-weight: 400;
    text-decoration:underline;
    font-size: 14px;
    line-height: 18px;
    margin:0;    
    padding-top: 10px;
  }
</style>