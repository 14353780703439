<template>
  <div>
      <v-dialog v-model="dialogProp.dialog" max-width="70%" persistent>
        <v-card class="rounded-lg">
        <v-card-title class="pt-12 px-12">
            <h2 class="pb-2" v-html="dialogProp.title"></h2>
            <v-divider class="bigger-border"></v-divider>
        </v-card-title>

        <v-btn class="close-btn" icon @click="clickedBtn('cancel')">
            <v-icon size="35">mdi-close</v-icon>
        </v-btn>
        
        <v-card-text>
            <p class="pa-6" v-html="dialogProp.description"></p>
        </v-card-text>

        <v-card-actions class="pb-5 px-12">
            <v-spacer></v-spacer>
            <v-btn v-if="dialogProp.cancel" text @click="clickedBtn('cancel')" min-width="200" >
            {{dialogProp.cancel}}
            </v-btn>

            <v-btn v-if="dialogProp.confirm" color="secondary"  min-width="200"  @click="clickedBtn('confirm')">
            {{dialogProp.confirm}}
            </v-btn>
        </v-card-actions>
        </v-card>
        
    </v-dialog>
   
  </div>
</template>

<script>
export default {
    data: function() {
        return {
        }
    },
    methods: {
        clickedBtn(event){          
            this.$emit('clicked',{ event, data: this.dialogProp.data});
            this.dialogProp.dialog = false;
        }
    },
    props: ['dialogProp']
};
</script>

<style scoped>
.close-btn{
    position: absolute;
    top: 10px;
    right: 10px;
}
.v-card__title{
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}
.v-card__title span{    
    font-size: 30px;
}
.bigger-border{
    border-bottom: 1px solid black !important;
    width: 100%;
}
p{
    font-size: 14px !important;
    font-weight: 400;
    color: black;
}
</style>