
<template>
  <div class="account-info">
    <h2 class="text-center mx-auto d-block mb-4">ACCOUNT MANAGER INFO</h2>
    <v-alert class="tneutral-alert-info" color="#F2F3ED">
        <p class="mb-12">
            This is the application process for those who <span class="alert-bold">represent an organization wanting to open an account in the T-Neutral Platform</span> to certify their Reduction
            Projects and access the T-Neutral textile credits hub "Reduction Projects Hub". <br><br>
            This is a <span class="alert-bold">verification based process</span>, therefore, prior to receiving access to the platform our Technical Team must review and approve all information
            submited, and request further documentation in needed, as well as confirm payment of the Account Opening Fee (Currently 0€ throughout 2022).<br><br>
            By opening an account in the T-Neutral Platform you are also <span class="alert-bold">automatically pre-approved for our <span class="text-decoration-underline">Central Registry</span> </span>, our public registry of information
            regarding certified Reduction Projects, Responsible Producers, and textile offsets issued and retired. Pre-approval means <span class="alert-bold">you can start the listing and
                registration process of a Reduction Project as soon as your T-Neutral account is activated</span>, as our Platform and Central Registry are seamlessly connected
            and are both managed from your Account Manager Panel.
        </p>
        <p>
            The <span class="alert-bold">steps</span> for the Reduction Project Account Application are the following. For any enquiries, you can contact us at verification@tneutral.com.<br><br><br>
            <ol>
                <li class="pl-4 mb-3">Understand the <span class="text-decoration-underline">T-Neutral Platform Operating Guidelines</span> and what <span class="text-decoration-underline">Account Opening Fee</span> applies to your case.</li>
                <li class="pl-4 mb-3">Complete the information related to the Account Manager, the person opening the account and ultimately managing it in representation of the
                    Organization owner of the Reduction Projects.</li>
                <li class="pl-4 mb-3">Complete the information related to the Organization, the entity that will ultimately hold ownership of the Reduction Projects.</li>
                <li class="pl-4 mb-3">Upload all "Know Your Customer" required legal documents for correct identification and validation of Account Manager and Organization.
                    <span class="alert-bold">IMPORTANT</span>: have them ready before hand to be able to finalize this aplication. Find here a list of all documents needed to prepare them in advance.</li>
                <li class="pl-4 mb-3">Review and accept the T-Neutral Platform Terms of Use.</li>
                <li class="pl-4 mb-3">Press "Submit" and our Technical Team will review the application and send an activation email when the information is validated. In case of incorrect
                    or incomplete information, our Technical team will contact the Account Manager accordingly.</li>
                <li class="pl-4 mb-3">Once the account is activated the Accont Manager will receive the invoice and payment details to fomalize the account opening. Failure to pay account
                    will result in account deactivation.</li>
            </ol>
        </p>
    </v-alert>
    <h2 class="mb-0">Account Manager Data</h2>
    <v-divider class="mt-2" color="black"></v-divider>
    <div class="pt-4" autocomplete="off" >
        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field validate-on-blur v-model="reductionFormModel.AccountApplicationForm_AccountManagerFirstName"
                            label="First Name *"
                            :rules="ruleRequired"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field validate-on-blur v-model="reductionFormModel.AccountApplicationForm_AccountManagerLastName"
                            label="Last Name *"
                            :rules="ruleRequired"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field validate-on-blur v-model="reductionFormModel.AccountApplicationForm_AccountManagerRol"
                          label="Role in the organization (Job Title) *"
                          :rules="ruleRequired"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field validate-on-blur v-model="reductionFormModel.AccountApplicationForm_AccountManagerTelephone"
                            label="Telephone number *"
                            type="tel"
                            :rules="ruleRequired"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field validate-on-blur v-model="reductionFormModel.AccountApplicationForm_AccountManagerEmail" 
                            label="Email *"
                            :rules="[...ruleRequired,...ruleEmail]"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" style="position: relative;">
                        <v-text-field validate-on-blur 
                            v-model="reductionFormModel.AccountApplicationForm_AccountManagerTaxIdentificationDocument"
                            label="Personal ID Number *"
                            :rules="ruleRequired"
                        ></v-text-field>
                       <v-btn color="secondary" class="info-icon-input" icon @click="openInformation">
                          <v-icon size="30">mdi-information</v-icon>
                       </v-btn>
                    </v-col>
                </v-row>
                <v-row></v-row>
            </v-col>           
        </v-row>
    </div>    
    <ConfirmDialog :dialogProp="dialogProp" @clicked="confirmation" />
  </div>
</template>

<script>
import { mapState,  } from "vuex";
import ConfirmDialog from '../common/ConfirmDialog.vue'
export default {
    components:{
        ConfirmDialog
    },
  data: function () {
    return {
      loading: null,
      formStepModel: null,
      dialogProp: {
        title: "Attention!",
        description:
          "${info}ServicLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac condimentum neque. Integer imperdiet porttitor porta. Praesent finibus nec est vel mattis. Cras fermentum nisi a odio molestie, sit amet interdum ex dictum. Vivamus vitae velit vitae ex convallis elementum.",
        cancel: "Cancel",
        confirm: "Confirm",
        dialog: false,
      },
    };
  },
  mounted() {},
  computed: {
    ...mapState(["api", "reductionFormModel"]),
    ruleRequired() {
      // eslint-disable-next-line no-extra-boolean-cast
      return [
        (v) =>
          (Array.isArray(v) ? v.length > 0 : !!v) || "* this field is required",
      ];
    },
    ruleEmail() {
      return [
        (v) => {
          let regx =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return regx.test(v) || "* incorrect email";
        },
      ];
    },
  },
  methods: {
    openInformation() {
      this.dialogProp = {
        title: "Attention!",
        description:
          "${info}ServicLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac condimentum neque. Integer imperdiet porttitor porta. Praesent finibus nec est vel mattis. Cras fermentum nisi a odio molestie, sit amet interdum ex dictum. Vivamus vitae velit vitae ex convallis elementum.",

        dialog: true,
      };
    },
    confirmation() {
      //console.log("openInformation ev", ev);
    },
  },
};
</script>
